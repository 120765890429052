import React, { useContext, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { useSocket } from "services/socket/hook";
import { NEW_TICKET_UPDATE } from "services/socket/events";
import { updateTicketsFilters } from "store/ticketsFilters/actions";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { otherCategories, ticketPhases } from "../../enum";
import { ticketsFilterBuilder } from "./constants";
import IssueDiscorveryTicketPhaseSelector from "./TicketsPhasesFilter/IssueDiscorveryTicketPhaseSelector/IssueDiscorveryTicketPhaseSelector";
import TicketsClassificationsFilter from "./TicketsClassificationsFilter/TicketsClassificationsFilter";
import TicketsInfoFilter from "./TicketsInfoFilter/TicketsInfoFilter";
import TicketPhaseSelector from "./TicketsPhasesFilter/TicketPhaseSelector/TicketPhaseSelector";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { pushIncomingTicket } from "store/tickets/incomingTickets/actions";
import { setActiveTicket } from "store/tickets/actions";
import MentionsFilter from "./MentionsFilter/MentionsFilter";
import { selectNextTicket } from "../ChatTickets/CustomerTicketsContainer/CustomerTickets/util/helper";
import TicketsFilterGroupActions from "./TicketsFilterGroupActions/TicketsFilterGroupActions";
import "./TicketsMessageTagsFilter/TicketsMessageTagsFilter.scss";
import "./TicketsFilterGroup.scss";


const { ISSUE_DISCOVERY } = ticketPhases;
const { AGENT_ASSISTANCE } = otherCategories;

const TicketsFilterGroup = ({
    showFilters,
    toggleFilters,
    handleRefreshData,
    refreshData,
    selectedTicketHeader,
}) => {
    const socket = useSocket();

    const dispatch = useDispatch();

    const { userId } = useSelector((state) => state.auth?.user);
    const { ticketsfilterRequest } = useSelector(
        (state) => state?.ticketsFilters,
        shallowEqual
    );

    const { activeTicket } = useSelector((state) => state.tickets);

    const {
        ticketPhase,
        classification,
        ticketInfoValue,
        isMentioned,
        followUpActive,
        ticketSource,
        agentId,
        search
    } = ticketsfilterRequest || {};

    const [fetchingCount, setFetchingCount] = useState(false);
    const [countsData, setCountsData] = useState({});
    const toastMessage = useContext(ToastContext);
    const showTicketInfoFilter = ticketPhase || isMentioned || followUpActive;
    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const handleFilterChange = (filterName, filterValue, filterOption) => {
        dispatch(
            updateTicketsFilters({
                filterName,
                filterValue,
                filterOption,
            })
        );
    };

    const getInitialTicketsCount = async (hideFetching) => {
        try {
            !hideFetching && setFetchingCount(true);

            const res = await API.get(apiRoute?.getTicketsCount, {
                params: {
                    ...ticketsfilterRequest,
                    search
                },
            });

            if (res.status === 200) {
                setCountsData(res.data.data);
                setFetchingCount(false);
            }
        } catch (err) {
            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            const errMssg = getErrorMessage(err);
            setFetchingCount(false);
            toastMessage(errMssg, dataQueryStatus?.ERROR);
        }
    };

    const isTicketOnAgentAssistancePlusIssueDiscoPhase =
        ticketPhase === ISSUE_DISCOVERY &&
        selectedTicketHeader === AGENT_ASSISTANCE;

    const handleUpdateProvision = (ticket) => {
        const parsedTicket = JSON.parse(ticket);

        if (parsedTicket?.newTicketPhase) {
            if (
                activeTicket?.ticketId === parsedTicket?.ticketId &&
                isTicketOnAgentAssistancePlusIssueDiscoPhase
            ) {
                // the goal is to prevent the ticket from leaving the screen if it changes phase while being engaged with a guide
                return;
            }
        }
        getInitialTicketsCount();
        if (parsedTicket?.ticketStatus === false) {
            toastMessage("Ticket has been closed");
        } else {
            if (parsedTicket?.newTicketPhase) {
                toastMessage("Ticket Moved to next phase");
            }

            if (
                parsedTicket?.commentResolved?.[0] &&
                parsedTicket?.commentResolved?.[1] === userId
            ) {
                if (activeTicket?.ticketId === parsedTicket?.ticketId) {
                    selectNextTicket(
                        activeTicket,
                        sortedTickets,
                        (ticket, ticketId) => {
                            dispatch(setActiveTicket(ticket));
                        }
                    );
                }
            }

            dispatch(pushIncomingTicket({ ...parsedTicket, isNew: true }));
        }
    };

    useEffect(() => {
        getInitialTicketsCount(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketPhase, ticketSource, refreshData, agentId, search]);

    useEffect(() => {
        // socket?.on(UPDATE_WORKSPACE_INBOX_COUNT, getInitialTicketsCount);
        socket?.on(NEW_TICKET_UPDATE, handleUpdateProvision);
        return () => {
            // socket.off(UPDATE_WORKSPACE_INBOX_COUNT);
            socket?.off(NEW_TICKET_UPDATE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTicket]);

    return (
        <div
            className={`tickets__filter_group tickets__filter ${!showFilters ? "bordered" : ""
                }`}
            id='ticketsFilterGroup'>
            <div className={`tickets__filter__container relative__layout`}>
                <div className='host__filters' key={showFilters}>
                    <div className='phase__class__container'>
                            <IssueDiscorveryTicketPhaseSelector
                                countsData={countsData?.ticketphase}
                                selectedTicketPhase={ticketPhase}
                                fetchingCount={fetchingCount}
                                handleTicketPhaseSelect={(val) =>
                                    handleFilterChange("ticketPhase", val)
                                }
                                handleTicketSourceSelect={(val) =>
                                    handleFilterChange("ticketSource", val)
                                }
                                ticketSource={ticketSource}
                            />
                        <div
                            className={`${showFilters ? "phase--class" : ""} `}>
                            <TicketPhaseSelector
                                countsData={countsData?.ticketphase}
                                selectedTicketPhase={ticketPhase}
                                fetchingCount={fetchingCount}
                                handleTicketPhaseSelect={(val) =>
                                    handleFilterChange("ticketPhase", val)
                                }
                                handleTicketSourceSelect={(val) =>
                                    handleFilterChange("ticketSource", val)
                                }
                                ticketSource={ticketSource}
                            />
                            {/* <FollowUpFilter
                                countValue={countsData?.others?.followup}
                                label={ticketsFilterBuilder?.FOLLOW_UP?.title}
                                isActive={followUpActive}
                                handleFilterChange={() =>
                                    handleFilterChange("followUpActive", true)
                                }
                                fetchingCount={fetchingCount}
                            /> */}
                            <MentionsFilter
                                countValue={countsData?.others?.isMentioned}
                                label={ticketsFilterBuilder?.MENTIONS?.title}
                                isActive={isMentioned}
                                handleFilterChange={() =>
                                    handleFilterChange("isMentioned", true)
                                }
                                fetchingCount={fetchingCount}
                            />
                            {ticketPhase ? (
                                ticketPhase !== ISSUE_DISCOVERY && (
                                    <TicketsClassificationsFilter
                                        countsData={countsData?.classifications}
                                        selectedTicketClassification={
                                            classification
                                        }
                                        fetchingCount={fetchingCount}
                                        handleTicketClassificationSelect={(
                                            val
                                        ) =>
                                            handleFilterChange(
                                                "classification",
                                                val
                                            )
                                        }
                                    />
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                        {/*  */}
                        {showFilters && (
                            <TicketsFilterGroupActions
                                showFilters={showFilters}
                                toggleFilters={toggleFilters}
                                handleRefreshData={handleRefreshData}
                            />
                        )}
                    </div>
                    {showTicketInfoFilter ? (
                        <div>
                            <TicketsInfoFilter
                                filters={{
                                    ticketPhase,
                                    classification,
                                    isMentioned,
                                    followUpActive,
                                }}
                                refreshData={refreshData}
                                selectedTicketInfo={ticketInfoValue}
                                handleTicketInfoSelect={(val, filterOption) =>
                                    handleFilterChange(
                                        "ticketInfoValue",
                                        val,
                                        filterOption
                                    )
                                }
                                showingFilters={showFilters}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {/*  */}
                    {!showFilters && (
                        <TicketsFilterGroupActions
                            showFilters={showFilters}
                            toggleFilters={toggleFilters}
                            handleRefreshData={handleRefreshData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TicketsFilterGroup;
