import React, { useContext, useEffect, useState } from "react";
import { Button, Textarea } from "components/ui";
import imageLinks from "assets/images";
import { ReactSVG } from "react-svg";
import { IS_NOT_TYPING, IS_TYPING } from "services/socket/events";
import useIsTyping from "use-is-typing";
import UploadIcons from "./UploadIcons/UploadIcons";
import UploadPreview from "./UploadIcons/UploadPreview/UploadPreview";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage, appendEmoji } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { CustomEmojiPicker } from "components/ui";
import "./LiveChatInput.scss";
import { useSocket } from "services/socket/hook";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;
const { LOADING, ERROR, DATAMODE } = dataQueryStatus;

const LiveChatInput = ({
    ticketId,
    handleNewMessage,
    cannedMessage,
    setCannedMessage,
    adjustTextFieldSize=() => null,
    toggleTextFieldSize=() => null,
    disabledInput,
}) => {
    const [isTyping, inputRef] = useIsTyping();
    const toastMessage = useContext(ToastContext);

    const [request, updateRequest] = useState({
        message: "",
        fileAttachments: [],
    });

    const [uploads, updateUploads] = useState([]);

    const [selectedMedia, setSelectedMedia] = useState({});
    const [status, setStatus] = useState("");
    const [showModal, toggleModal] = useState(false);

    const socket = useSocket();

    const handleRemoveFile = (fileName, fileIndex) => {
        updateUploads((prev) =>
            prev?.filter((upload, uploadIndex) => uploadIndex !== fileIndex)
        );
        updateRequest((prev) => ({
            ...prev,
            fileAttachments: prev?.fileAttachments?.filter(
                (upload, uploadIndex) => uploadIndex !== fileIndex
            ),
        }));
    };

    const handleRetryUpload = async (file) => {
        try {
            let prevUploads = [...uploads];
            prevUploads[file?.fileIndex] = {
                ...file,
                uploadStatus: LOADING,
            };

            updateUploads(prevUploads);

            const url = apiRoute.mediaUpload;
            const formData = new FormData();

            let httpRequest = new AbortController();

            formData.append("file", file?.file);

            let resolvedUpload = await API.post(
                url,
                {
                    file: file?.name,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            const { fileKey, link } = resolvedUpload.data || {};
            await uploadFile(link, file);

            let newUpload = {
                ...file,
                fileAttachmentUrl: fileKey,
                uploadStatus: DATAMODE,
            };

            let newUploads = [...uploads];

            newUploads[file?.fileIndex] = {
                ...newUpload,
                uploadStatus: DATAMODE,
            };

            updateUploads(newUploads);

            updateRequest((prev) => ({
                ...prev,
                fileAttachments: newUploads?.filter(
                    (upload) => upload?.uploadStatus === DATAMODE
                ),
            }));
        } catch (err) {
            let prevUploads = [...uploads];
            prevUploads[file?.fileIndex] = {
                ...file,
                uploadStatus: ERROR,
            };

            updateUploads(prevUploads);

            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const uploadFile = async (url, originalFile) => {
        try {
            const options = {
                disableAuthorization: true,
            };

            const reader = new FileReader();
            reader.readAsArrayBuffer(originalFile);
            reader.onload = async () => {
                const binaryData = reader.result;
                const res = await API.put(`${url}`, binaryData, options);
                if (res.status === 200) {
                    return true;
                } else {
                    return false;
                }
            };
        } catch (err) {
            return false;
        }
    };

    const handleUpload = async (files) => {
        /*
        Promise.allSettled used here returns responses (both success and failure) for each individual request, 
        thus the need for the removal of try & catch block.
        */

        if (uploads?.length === 0) {
            setStatus(LOADING);
        }

        const uploadedFiles = files?.map(async (media) => {
            const url = apiRoute.generateUploadLink;

            let httpRequest = new AbortController();

            const formData = new FormData();

            formData.append("file", media?.file);

            const res = await API.put(
                url,
                {
                    file: media?.file?.name,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            if (res.status === 200) {
                const { data } = res.data;
                const { fileKey, link } = data || {};
                const { file, ...rest } = media;
                await uploadFile(link, media?.file);
                return {
                    ...rest,
                    fileAttachmentUrl: fileKey,
                };
            }
        });

        const resolvedUploads = await Promise.allSettled(uploadedFiles);

        let totalRequests = uploadedFiles?.length;
        let totalFailedRequests = 0;
        let errorMsg = "";

        let resolvedUpload = resolvedUploads?.map(
            ({ status, reason, value }, fileIndex) => {
                errorMsg = reason;
                if (status === "fulfilled") {
                    value = { ...value, fileIndex, uploadStatus: DATAMODE };
                } else {
                    totalFailedRequests += 1;
                    value = {
                        ...files[fileIndex],
                        fileIndex,
                        uploadStatus: ERROR,
                    };
                }
                return value;
            }
        );

        if (totalFailedRequests === totalRequests) {
            setStatus(ERROR);
            const message = getErrorMessage(errorMsg);
            toastMessage(message, ERROR);
        } else {
            updateRequest((prev) => ({
                ...prev,
                fileAttachments: resolvedUpload?.filter(
                    (upload) => upload?.uploadStatus === DATAMODE
                ),
            }));
            setStatus(DATAMODE);
        }

        updateUploads([...uploads, ...resolvedUpload]);
    };

    const sendNewMessage = () => {
        handleNewMessage(request);
        updateRequest({
            message: "",
            fileAttachments: [],
        });
        updateUploads([]);
        setCannedMessage?.("");
        toggleTextFieldSize(false);
    };

    const handleEnterKeyCapture = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendNewMessage();
        }
    };

    const adjustTextAreaHeight = (value, isCannedResponse) => {
        const chatInput = document.getElementById("chat-input");

        const computedScrollHeight =
            Math.floor((value.length / 45) * 24) + chatInput.scrollHeight;

        const scrollHeight = isCannedResponse
            ? computedScrollHeight > 200
                ? 199
                : computedScrollHeight
            : chatInput.scrollHeight;

        if (chatInput) {
            if (scrollHeight > 70) {
                toggleTextFieldSize(true);
            }

            if (scrollHeight < 200) {
                chatInput.style.height = scrollHeight - 10 + "px";

                document.querySelector("#chatInput").style.height =
                    scrollHeight + 5 + "px";
            }

            if (value?.length < 1) {
                chatInput.style.height = "20px";
                document.querySelector("#chatInput").style.height = 64 + "px";
            }
        }
    };

    const handleTyping = (e) => {
        const { value } = e.target;

        adjustTextAreaHeight(value);

        updateRequest({ ...request, message: value });

        if (value?.length < 1) {
            setCannedMessage?.("");
            toggleTextFieldSize(false);
        }
    };

    const handleEmojiSelect = (emoji) => {
        const chatInput = document.getElementById("chat-input");
        const cursorPosition = chatInput?.selectionStart;

        updateRequest((prev) => ({
            ...prev,
            message: appendEmoji(prev?.message, cursorPosition, emoji),
        }));

        chatInput.focus();
    };

    useEffect(() => {
        const decidedEvent = isTyping ? IS_TYPING : IS_NOT_TYPING;
        socket?.emit?.(decidedEvent, { ticketId });
        // [...] call API
        //eslint-disable-next-line
    }, [isTyping]);

    useEffect(() => {
        !socket.connected && socket.connect();
        return () => {
            socket?.emit?.(IS_NOT_TYPING, { ticketId });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateRequest((prev) => ({ ...prev, message: cannedMessage }));
        if (cannedMessage) {
            adjustTextAreaHeight(cannedMessage, true);
        }
        // eslint-disable-next-line
    }, [cannedMessage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        sendNewMessage();
    };

    return (
        <div id='chatInput' className={`chat__input--group`}>
            <form
                onSubmit={handleSubmit}
                className={`row action__group ${
                    adjustTextFieldSize ? "adjust__action__group" : ""
                }`}>
                <div className='col-9 ps-0'>
                    {uploads?.length > 0 ? (
                        <UploadPreview
                            uploads={uploads}
                            status={status}
                            handleRemoveFile={handleRemoveFile}
                            handleRetry={(file) => handleRetryUpload(file)}
                            maximize={(
                                fileAttachmentType,
                                fileAttachmentName,
                                fileAttachmentUrl
                            ) => {
                                setSelectedMedia({
                                    fileAttachmentType,
                                    fileAttachmentName,
                                    fileAttachmentUrl,
                                });
                                toggleModal(true);
                            }}
                            disableClick={status !== DATAMODE}
                        />
                    ) : (
                        <Textarea
                            id='chat-input'
                            ref={inputRef}
                            placeholder='Reply message...'
                            value={request?.message}
                            onChange={handleTyping}
                            grpClass='w-100'
                            hideLabel={true}
                            onKeyPress={handleEnterKeyCapture}
                            disabled={disabledInput}
                        />
                    )}
                </div>
                <div
                    className={`col-3 action__group--actions px-0 ${
                        adjustTextFieldSize ? "adjust__actions" : ""
                    }`}>
                    {/* {uploads?.length === 0 && ( */}
                        <>
                            <CustomEmojiPicker
                                handleEmojiSelect={handleEmojiSelect}
                                togglerClass='emoji__picker__toggle'
                            />
                            <ComponentAccessControl
                                baseResources={[
                                    `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                                ]}>
                                <UploadIcons
                                    uploads={uploads}
                                    updateUploads={updateUploads}
                                    isDisabled={false}
                                    showModal={showModal}
                                    toggleModal={toggleModal}
                                    handleUpload={handleUpload}
                                    selectedMedia={selectedMedia}
                                />
                            </ComponentAccessControl>
                        </>
                    {/* )} */}
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <Button
                            type='submit'
                            classType='primary'
                            otherClass='send__button'
                            disabled={
                                disabledInput || uploads?.length > 0
                                    ? status === LOADING ||
                                      status === "" ||
                                      status === ERROR
                                    : request?.message === ""
                            }
                            onClick={sendNewMessage}>
                            <ReactSVG src={imageLinks?.icons?.sendIcon} />
                        </Button>
                    </ComponentAccessControl>
                </div>
            </form>
        </div>
    );
};

export default LiveChatInput;
