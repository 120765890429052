import React from "react";
import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import { useSelector } from "react-redux";
import { ticketsFilterBuilder } from "../../constants";
import TicketFilterSelector from "../../TicketFilterSelector/TicketFilterSelector";
import { ISSUE_DISCOVERY } from "../../../ChatTickets/common/TicketStatus/enum";
import { ticketSourcesLabel } from "./enum";
import { truncateString } from "utils/helper";
import "./TicketPhaseSelector.scss";

const TicketPhaseSelector = ({
    countsData,
    fetchingCount,
    handleTicketPhaseSelect,
    selectedTicketPhase,
    handleTicketSourceSelect,
    ticketSource,
}) => {
    const { userId } = useSelector((state) => state.auth?.user);
    const { incomingTickets } = useSelector((state) => state?.incomingTickets);

    return (
        <div>
            {/* <h6 className='mb-3'>
                <span style={{ opacity: 0 }}>Night</span>
            </h6> */}
            <div className='d-flex'>
                {Object.entries(ticketPhases).map(([_, ticketPhase], index) => {
                    if (ticketPhase === ticketPhases.ISSUE_DISCOVERY) return "";
                    const filterBlock = ticketsFilterBuilder[ticketPhase];

                    const filterByTicketPhase = (ticket) => {
                        return ticket.ticketPhase === ticketPhase;
                    };

                    const filterByMessageTag = (ticket) => {
                        return ticket.issueDiscovered === true;
                    };

                    const filterByTicketInfoValue = (ticket) => {
                        let isAssigned =
                            ticket?.assigned === true &&
                            ticket?.assignedTeamMemberId === userId;
                        return isAssigned;
                    };

                    const filterByTicketStatus = (ticket) => {
                        return (
                            ticket.ticketStatus === true &&
                            ticket.isNew === true
                        );
                    };

                    const isActive = selectedTicketPhase === ticketPhase;

                    const onlyNewTickets = incomingTickets
                        ?.filter(filterByTicketPhase)
                        .filter(filterByTicketStatus);
                    const newTicketsAvailable =
                        ticketPhase === ISSUE_DISCOVERY
                            ? onlyNewTickets.filter(filterByMessageTag)
                            : onlyNewTickets?.filter(filterByTicketInfoValue);

                    return (
                        <>
                            {ticketPhase === ISSUE_DISCOVERY ? (
                                <TicketFilterSelector
                                    key={index}
                                    label={
                                        isActive
                                            ? ticketSourcesLabel[ticketSource]
                                                  ?.label || filterBlock?.title
                                            : truncateString(
                                                  ticketSourcesLabel[
                                                      ticketSource
                                                  ]?.label ||
                                                      filterBlock?.title,
                                                  9
                                              )
                                    }
                                    countValue={
                                        countsData?.[filterBlock?.["countKey"]]
                                    }
                                    isActive={isActive}
                                    onClick={() => {
                                        handleTicketPhaseSelect(ticketPhase);
                                    }}
                                    hasNew={newTicketsAvailable.length > 0}
                                    fetchingCount={fetchingCount}
                                    activeStyles={filterBlock?.activeStyles}
                                    handleDropdownAction={
                                        handleTicketSourceSelect
                                    }
                                    showFilterSelectorDropdown={
                                        selectedTicketPhase === ISSUE_DISCOVERY
                                    }
                                    ticketSource={ticketSource}
                                />
                            ) : (
                                <TicketFilterSelector
                                    key={index}
                                    label={
                                        isActive
                                            ? filterBlock?.title
                                            : truncateString(
                                                  filterBlock?.title,
                                                  index === 1 ? 10 : 11
                                              )
                                    }
                                    countValue={
                                        countsData?.[filterBlock?.["countKey"]]
                                    }
                                    isActive={isActive}
                                    onClick={() => {
                                        handleTicketPhaseSelect(ticketPhase);
                                    }}
                                    hasNew={newTicketsAvailable.length > 0}
                                    fetchingCount={fetchingCount}
                                    activeStyles={filterBlock?.activeStyles}
                                />
                            )}
                        </>
                    );
                })}
                <div className='message__tag--hr'></div>
            </div>
        </div>
    );
};

export default TicketPhaseSelector;
